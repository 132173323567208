import { useEffect, useState } from "react";
import { message } from "antd";
import Header from "components/Header";
import CardList from "components/CardList";
import { DollarCircleOutlined } from "@ant-design/icons";
import { listInvoices } from "api/smaService";
import "./style.scss";

function Invoices() {
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        fetchInvoices();
    }, []);

    // Busca as invoices (mock ou chamada real)
    const fetchInvoices = async () => {
        const result = await listInvoices();
        if (result.success) {
            setInvoices(result.data);
        } else {
            message.error(result.message || "Error fetching invoices");
        }
    };

    // Abre o link de pagamento/visualização em outra aba
    const handleOpenInvoice = (invoiceData) => {
        console.log(invoiceData.link);
        window.open(invoiceData.link, "_blank");
    };

    // Define a cor do dot conforme o status
    const getStatusColorClass = (status) => {
        switch (status) {
            case "Paid":
                return "green";
            case "Open":
                return "yellow";
            case "Late":
                return "red";
            default:
                return "grey";
        }
    };

    const dataList = invoices.map((invoice) => {
        const colorClass = getStatusColorClass(invoice.status);

        return {
            // (Opcional) Se quiser colocar um ícone no topo do card
            // icon: <YourIconHere />,

            // Exemplo: mês/ano
            title: invoice.date, // ex: "December/24"

            // Lista de infos adicionais
            list: [
                {
                    icon: <DollarCircleOutlined />,
                    text: `Value: $${invoice.value}`,
                },
                {
                    // Não definimos icon, pois queremos tudo junto em `text`
                    text: (
                        <>
                            <span className={`invoice-status-dot ${colorClass}`} />
                            <span style={{ marginLeft: 8 }}>{invoice.status}</span>
                        </>
                    ),
                },
            ],

            // Menu (3 pontinhos) para ações
            actions: [
                {
                    name: "View Invoice",
                    action: () => handleOpenInvoice(invoice),
                },
            ],
        };
    });

    return (
        <div className="Invoices">
            <Header title="Invoices" refreshAction={fetchInvoices} />
            <CardList data={dataList} />
        </div>
    );
}

export default Invoices;
